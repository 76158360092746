const urlParams = new URLSearchParams(window.location.search);

var page_vars=getUrlVars();
var page_lng="es";
/*
if(page_vars["lng"])
{
	page_lng=page_vars["lng"];
}else{
	if(localStorage["wircos_lng"])
		page_lng=localStorage["wircos_lng"];
	else{
		var language=navigator.language? navigator.language : navigator.userLanguage;
		if((language.indexOf('es') > -1) || (language.indexOf('ca')>-1))
		{
			page_lng="es";	
		}else{
			page_lng="en";	
		}
	}
}
*/
const server_url= location.hostname === "localhost" 
? "http://www.lvh.me:8080"
: "https://server.wircos.com";

function get_msg(msg)
{
	const e=document.querySelector("[aria-msg="+msg+"_"+page_lng+"]");
	if(e)
		return(e.innerHTML);
	
	return("");
}
function show_lng(lng)
{
	document.querySelectorAll(".lng").forEach(element=>{
		element.style.display = 'none';
	});
	document.querySelectorAll(".lng_"+lng).forEach(element=>{
		element.style.display = 'inline';
	});	
	page_lng=lng;
	localStorage["wircos_lng"]=lng;
}
	
function lng_click(e)
{
	var lng=this.getAttribute("href");
	show_lng(lng);
	e.preventDefault();
}	

function getUrlVars() 
{
	var vars = {};
	var parts = window.location.href.replace(/[?&#]+([^=&#]+)=([^&#]*)/gi, function(m,key,value) 
	{
		vars[key] = value;
	});
	return(vars);
}

function formatBytes(bytes, decimals = 2) 
{
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function upload_nft(e)
{
	e.preventDefault();
    
	document.getElementById("btn_upload").classList.add("hide");
	document.getElementById("upload_error").classList.add("hide");
	document.getElementById("nft_uploading").classList.remove("hide");
	set_upload_step("progress_starting");

	const fields=document.getElementById("nft_form").querySelectorAll("[required]");
	for(let a=0;a<fields.length;a++)
	{
		if(!fields[a].validity.valid)
		{
			let error=401;
			if(fields[a].validity.valueMissing)
				error=400;
			show_upload_error(error,fields[a].getAttribute("name"));
			return;

		}
	};

	let server=server_url.replace("http","ws");
	server=server.replace("https","wss");
	
	let socket=null;
	try{
		socket = new WebSocket(server+"/"+
					//localStorage["wircos_session"]+"/"+
					//localStorage["wircos_user"]
					document.getElementById("e_user_email").value);
	}catch(e)
	{
		show_upload_error("connecting",null,false);
	}

	socket.addEventListener('error',function(event){
		show_upload_error("connecting",null,false);
	});

	socket.addEventListener('open', function (event) {  
	}); 

	socket.addEventListener('close', function (event) { 
		if(!event.wasClean)
			console.log("Socket closed not wasClean",event);
	});
	
	socket.addEventListener('message', function (event) { 
		const data=JSON.parse(event.data);
		switch(data.msg)
		{
			case "error":
				show_upload_error(data.error);
				break;

			case "step":
				let extra=null;
				if(data.extra) extra=data.extra;
				set_upload_step("progress_"+data.data,extra);
				break;

			case "progress":
				set_upload_progress(data.data);
				break;

			case "start":
				start_upload_nft(e);
				break;
			
		}
		//console.log('Message from server ', event.data); 
	});
}

function show_upload_error(error,info=null,code=true)
{
	let error_msg=get_msg("error_"+error);
	if(info)
	{
		let parent=document.getElementById("e_"+info).parentElement;
		if(!parent.querySelector("label")) parent=parent.parentElement;
 			error_msg+=": "+parent.querySelector("label").innerText;
	}
	if(code)
		error_msg+="<br>Error: "+error;
					
	document.getElementById("upload_error").innerHTML=error_msg;	
	document.getElementById("upload_error").classList.remove("hide");	
							
	document.getElementById("btn_upload").classList.remove("hide");	
	document.getElementById("nft_uploading").classList.add("hide");		
}

function set_upload_step(step,extra=null)
{
	let msg=get_msg(step);
	if(extra)
		msg+=" ("+extra+")";

	document.getElementById("uploading_step").innerHTML=msg;
	
	document.getElementById("uploading_progress").value=0;
}
function set_upload_progress(progress)
{
	document.getElementById("uploading_progress").style.width=progress+"%";
	document.getElementById("uploading_value").innerText=progress+"%";
}

function start_upload_nft(e)
{
	let nft_form=document.getElementById("nft_form");
    let data = new FormData(nft_form);

	data.append("user_lng",page_lng);

	document.querySelectorAll(".upload_error_type").forEach(element=>{
			element.classList.add("hide");
	});

	let request = new XMLHttpRequest();
	request.open('POST', server_url+'/upload_nft'); 

	request.upload.addEventListener('progress', function(e) {
		set_upload_progress(parseInt((e.loaded / e.total)*100));
	});

	request.addEventListener('load', function(e) {
		if (request.status >= 200 && request.status <= 299)
		{
				const response=JSON.parse(request.response);
				document.getElementById("nft_uploading").classList.add("hide");
			
                let result;
                if(response.status)
                {   
					if(document.getElementById("nfts_grid"))
						load_nfts("nfts_grid",localStorage["wircos_user"],"nfts_grid_empty");	
					document.getElementById("nft_checkout_url").setAttribute("href",response.data.nft_url);
					document.getElementById("nft_checkout_url").innerHTML=response.data.nft_url;
					document.querySelector(".main_form").classList.add("hide");
        			document.getElementById("upload_success").classList.remove("hide");
		        }else{
					show_upload_error(response.error,response.info);
                }
		
		}else{
			modal_show("Error","Error upload","error");
		}
	});
	set_upload_step("progress_starting");
	request.send(data);
}

async function load_nfts(where,email,empty)
{
	document.getElementById(empty).classList.add("hide");

	const res=await fetch(server_url+"/nfts/"+email);
	let data = await res.json();
	if(!data) return;
	if(!data.nfts) return;

	show_nfts(where,data.nfts,empty);
	show_stats(data.stats);
}
function show_stats(stats)
{
	if(stats["plan"])
	{
		document.getElementById("account_stats_plan_name").innerText=stats["plan"]["name"];
		if(stats["plan"]["nfts"]==0)
			document.getElementById("account_stats_plan_info_nfts").innerText=get_msg("stats_unlimited");
		else
			document.getElementById("account_stats_plan_info_nfts").innerText=stats["plan"]["nfts"];
		if(!stats["plan"]["space"]["num"])
			document.getElementById("account_stats_plan_info_space").innerText=get_msg("stats_unlimited");
		else
			document.getElementById("account_stats_plan_info_space").innerText=stats["plan"]["space"]["num"]+" "+
																	  stats["plan"]["space"]["type"];
	}
	document.getElementById("account_stats_nfts").innerText=stats.count;
	document.getElementById("account_stats_size").innerText=stats["size"]["num"];
	document.getElementById("account_stats_size_type").innerText=stats["size"]["type"];
	if(stats["balance"])
	{
		document.getElementById("account_stats_balance").innerHTML=stats["balance"]+"<small>€</small>";
		document.querySelector(".transactions_info").classList.remove("hide");
	} 

}
function monument_functions(name,params)
{
	let functions={
		account_nft_click:async function(params)
		{
			const res = await fetch(server_url+"/get_nft", 
			{
				method: 'POST',
				headers: {
			  		'Accept': 'application/json',
			  		'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					account_email: document.getElementById("account_email").value,
					account_key: document.getElementById("account_key").value,
					nft_id:params.nft_id,
				})
			  });			  
			let nft = await res.json();
			if(!nft){ alert("error"); return;}
			if(!nft.status){alert("error: "+nft.error); return};

			document.getElementById("e_nft_id").value=nft.data.nft_id;
			document.getElementById("e_nft_title").value=nft.data.nft_title;
			document.getElementById("e_nft_description").value=nft.data.nft_description;
			if(nft.data.nft_information)
				document.getElementById("e_nft_information").value=nft.data.nft_information;
			else 
				document.getElementById("e_nft_information").value="";
			document.getElementById("e_nft_price").value=nft.data.nft_price;
			
			if(nft.data.nft_private) 
				document.getElementById("e_nft_private").value=nft.data.nft_private;
			else
				document.getElementById("e_nft_private").value="";
			document.getElementById("nft_thumb_image").setAttribute("src",nft.data.nft_thumbnail_url);
			document.getElementById("nft_thumb_image").classList.remove("hide");

			update_new_info(nft.data.nft_id);
			change_tab("create");

			//let changeEvent = new Event('change');
			//document.getElementById("my_item_mode").dispatchEvent(changeEvent);
		}
	}
	functions[name](params);
}
function account_nft_click(data)
{
	alert(data);
}
function show_nfts(parent,data,empty)
{
	let grid=document.getElementById(parent);
	grid.innerHTML="";

	if((data==null) || (data.length==0))
	{
		grid.classList.add("hide");
		document.getElementById(empty).classList.remove("hide");
		return;
	}

	grid.classList.remove("hide");

	data.forEach(function(nft) {
		let item=document.querySelector('.elements .nft_item').cloneNode(true);
		item.setAttribute("id","nft_"+nft.nft_id);
		item.setAttribute("aria-status",nft.nft_status);
		
		item.querySelector(".nft_thumbnail").setAttribute('src', nft.nft_thumbnail_url);
		let info=item.querySelector('.nft_info');
		info.querySelector(".nft_cost").append(nft.nft_price.toLocaleString("es-US"));
		
		info.querySelector(".nft_views").append(nft.nft_views);
		if(nft.nft_serie_total>1)
			info.querySelector(".nft_copies").append(nft.nft_copies+"/"+nft.nft_serie_total);
		else
			info.querySelector(".nft_stats_copies").classList.add("hide");
		item.querySelector(".nft_title").append(nft.nft_title);
		info.querySelector(".nft_description").append(nft.nft_description);

		item.addEventListener("click",function(e)
		{
			e.preventDefault();
			show_nft_options(nft.nft_id,
							item.querySelector(".nft_thumbnail").getAttribute('src'),
							nft.nft_title,
							info.querySelector(".nft_cost").innerHTML,
							item.getAttribute("aria-status")+page_lng,
							nft.nft_checkout, nft.nft_url
							);
		});

		grid.append(item);
	});
}

function change_section(element)
{
	if(document.querySelector(".account_content.active"))
	{
		document.querySelector(".account_content.active").classList.add("hide");
		document.querySelector(".account_content.active").classList.remove("active");
	}
			
	let content=element.getAttribute("aria-section");
	if(document.getElementById(content))
	{
		document.getElementById(content).classList.add("active");
		document.getElementById(content).classList.remove("hide");
	}	
	if(document.querySelector(".account_link.active"))
		document.querySelector(".account_link.active").classList.remove("active");
	element.classList.add("active");
	window.scrollTo({
		top: 0,
		behavior: "smooth"
	});
}

function show_featured_authors(slider,data=null,count=10)
{
	if(data) count=data.length;

	const parent=document.getElementById(slider);
	parent.innerHTML="";
	for(let a=0;a<count;a++)
	{
		let item=document.querySelector('.elements .explore_author_item').cloneNode(true);
		if(data)
		{
			item.setAttribute("href","/author.html?"+data[a].user_username);
			item.querySelector(".explore_author_name").innerText=data[a].user_username;
			item.querySelector(".explore_author_picture").setAttribute("src",data[a].user_picture);
			item.querySelector(".explore_author_picture").setAttribute("href",data[a].user_profile);
		}
		parent.appendChild(item);
	}
}


function load()
{
	show_lng(page_lng)
	document.querySelectorAll(".btn_lng").forEach(element=>{
		element.addEventListener('click', lng_click,false);
	});

	if(document.getElementById("header"))
		document.getElementById("header").classList.remove("header_hide");


	if(document.getElementById("btn_contact"))
	{
		document.getElementById("btn_contact").addEventListener("click",function(e)
			{
				e.preventDefault();
				show_contact_result(null);
				document.querySelectorAll("#contact_form input, textarea").forEach(element=>{
					if(element.getAttribute("type")=="checkbox")
					{
						element.parentElement.querySelector(".checkmark").classList.remove("contact_error");
						if(!element.checked)
							element.parentElement.querySelector(".checkmark").classList.add("contact_error");
					}else{
						element.classList.remove("contact_error");
						if(element.value=="")
							element.classList.add("contact_error");
					}
				});

				if(document.querySelector(".contact_error"))
				{
					show_contact_result("contact_error_empty");
				}else{

					fetch(server_url+"/sexy_kinks",{
						method: "POST",
						headers: {"Access-Control-Allow-Origin": "*",
								  "Content-Type": "application/json"
								},
						//body: form, 
						body:JSON.stringify({
							contact_name:document.getElementById("contact_name").value,
							contact_email:document.getElementById("contact_email").value,	
							contact_info:document.getElementById("contact_info").value,	
							contact_lng:document.getElementById("contact_lng").value		
						})
					}).then((response)=>{
						if (response.status >= 200 && response.status <= 299) 
							return response.json();
						
						show_contact_result("contact_error_server");
					}).then((response)=>{
						if(response.status)
						{   
							show_contact_result("contact_result_ok");
							document.getElementById("btn_contact").classList.add("hide");						
						}else{
							if(response.status==100)
								show_contact_result("contact_error_email");
							else
								show_contact_result("contact_error_server");
						}
					});
				}
			});
	}

	if(document.getElementById("btn_cookies"))
	{
		if(!localStorage["kink_cookies_accept"])
		{
			document.getElementById("cookies").classList.remove("hide");
			document.getElementById("btn_cookies").addEventListener("click",function(e)
			{
				e.preventDefault();
				document.getElementById("cookies").classList.add("hide");
				localStorage["kink_cookies_accept"]="yes";
			});
		}
	}
	document.querySelectorAll(".faq_question").forEach(element=>{
		element.addEventListener('click', function(e)
		{			
			e.preventDefault();
			this.classList.toggle("faq_arrow_extend");
			this.nextElementSibling.classList.toggle("faq_answer_visible");
		},false);
	});

	if(document.getElementById("btn_more"))
	{
		document.getElementById("btn_more").addEventListener("click",e=>{
			e.preventDefault();
			const kinks=document.querySelectorAll(".sexy_kink.hide");
			for(let a=0;a<3;a++)
			{
				if(a>=kinks.length)
				{
					document.getElementById("btn_more").classList.add("hide");
					break;
				}
				kinks[a].classList.remove("hide");
			}

		});
	}

	document.querySelectorAll("#nav_menu a").forEach(element=>{
		element.addEventListener('click', function()
		{
			document.getElementById("side-menu").checked=false; 
		},false);
	});
	return;

	document.querySelectorAll("[aria-btn-trigger]").forEach(element=>{
		element.addEventListener('keypress', function(e)
		{
			if(e.key==="Enter")
			{
				document.getElementById(this.getAttribute("aria-btn-trigger")).dispatchEvent(new Event('click'));
			}
		},false);
	});

	document.querySelectorAll(".select_picture").forEach(element=>{
		const target=document.getElementById(element.getAttribute("aria-target"));
		element.addEventListener('click', function(e)
		{
			e.preventDefault();
			target.click();
		});
		
		target.addEventListener('change', function(e)
		{
			e.preventDefault();
			if(this.files.length === 0) return;
			var imageFile = this.files[0];
		
			var reader = new FileReader();
			reader.onload = (function(aImg){
				 return function(e) 
				 { 
					const img=element.querySelector(".select_picture_image");
					img.setAttribute("src",e.target.result);
					img.classList.remove("hide");
					element.querySelector(".select_picture_default").classList.add("hide");
				}; 
			})(element);
			reader.readAsDataURL(imageFile);
		},false);
	});


	if(document.getElementById("upload_files"))	//btn_change_nft
	{
		document.getElementById("upload_files").addEventListener("click",function(e)
		{
			e.preventDefault();
			document.getElementById("e_nft_file").click();
		});
		if(document.getElementById("e_nft_file"))
			document.getElementById("e_nft_file").addEventListener("change",function(e)
			{
				e.preventDefault();
				if(this.files.length === 0) return;
				document.getElementById("upload_files").innerHTML="";
				//console.log(this.files[0]);
				
				let files_content="";
				let total_size=0;
				for(let f=0;f<this.files.length;f++)
				{
					files_content+="<div class='filelist_row'>";
					files_content+="<div class='filelist_name'>"+this.files[f].name+"</div>";
					files_content+="<div class='filelist_size'>"+formatBytes(this.files[f].size,2)+"</div>";
					files_content+="</div>";
					total_size+=this.files[f].size;
					//document.getElementById("nft_filename").innerHTML+="<big>"+this.files[f].name+"</big>";
					//document.getElementById("nft_filename").innerHTML+="<small>"+
					//							formatBytes(this.files[f].size,2)+"</small>";
				}
				document.getElementById("upload_files_resume_total").innerHTML=this.files.length;
				document.getElementById("upload_files_resume_siza").innerHTML=formatBytes(total_size,2);

				document.getElementById("upload_files").innerHTML=files_content;
				//document.getElementById("nft_filename").innerHTML=this.files[0].name;
				//document.getElementById("nft_filesize").innerHTML=formatBytes(this.files[0].size,2);
				document.querySelector(".upload_files_resume").classList.remove("hide");
				//document.querySelector("#btn_change_nft .select_picture_default").classList.add("hide");
			});
	}

	document.querySelectorAll("#nav_menu a").forEach(element=>{
			element.addEventListener('click', function()
			{
				document.getElementById("side-menu").checked=false; 
			},false);
		});
	
	if(document.getElementById("btn_cookies"))
	{
		if(!localStorage["kink_cookies_accept"])
		{
			document.getElementById("cookies").classList.remove("hide");
			document.getElementById("btn_cookies").addEventListener("click",function(e)
			{
				e.preventDefault();
				document.getElementById("cookies").classList.add("hide");
				localStorage["kink_cookies_accept"]="yes";
			});
		}
	}
	
	

	if(document.getElementById("btn_upload"))
		document.getElementById("btn_upload").addEventListener('click',upload_nft,false);
	
	if(document.getElementById("btn_again"))
		document.getElementById("btn_again").addEventListener('click',function(e)
		{
			e.preventDefault();
			if(document.getElementById("nfts_grid"))
				load_nfts("nfts_grid",localStorage["wircos_user"],"nfts_grid_empty");	
			
			document.getElementById("upload_success").classList.add("hide");
			document.querySelector(".main_form").classList.remove("hide");
			document.getElementById("btn_upload").classList.remove("hide");
			
			document.getElementById("nft_thumb_image").setAttribute("src",null);
			document.getElementById("nft_thumb_image").classList.add("hide");
			document.querySelector("#btn_change_thumbnail .select_picture_default").classList.remove("hide");

			document.getElementById("upload_files").innerHTML="";

			document.querySelector(".upload_files_resume").classList.add("hide");
			
			document.querySelectorAll(".field_empty").forEach(element=>{
					if(element.defaultValue)
						element.value=element.defaultValue;
					else
						element.value="";
			});
		},false);
	//show_logged(false);
	
	if(document.getElementById("account_page"))
	{
		document.getElementById("btn_logout").addEventListener('click',handleLogout);

		document.querySelector(".login_error").classList.add("hide");
		document.querySelector(".login_wait").classList.add("hide");

		const section=window.location.href.split("#");
		if(section.length>1)
		{
			const element=document.querySelector('[aria-section="'+section[1]+'"]');
			if(element) change_section(element);
		}
			
		document.getElementById("transaction_from").valueAsDate = new Date();
		document.getElementById("transaction_to").valueAsDate = new Date();

		document.getElementById("btn_transactions").addEventListener('click',function(e)
		{
			e.preventDefault();
			load_transactions();
		},false);

		document.querySelectorAll(".account_option").forEach(element=>{
			element.addEventListener('click', function(e)
			{	
				e.preventDefault();
				if(!element.classList.contains("active"))
				//if(document.getElementById("account_page"))
				{
					document.querySelector(".option_nft_edit").classList.add("hide");
					change_section(element);
				}		
			},false);

		});
	
		document.getElementById("btn_login").addEventListener('click',handleLogin);

		if(localStorage["wircos_session"])
		{
			test_token();
		}else{
			if(page_vars["token"])
			{
				if(localStorage["wircos_user"])
				{
					validate_token(page_vars["token"],function(status,data)
					{
						if(status)
						{ 
							localStorage["wircos_session"]=data;
							test_token();
						}else{
							show_logged(false);
						}
					});
				}else{
					show_logged(false);
				}
			}else{
				show_logged(false);
			}
		}
	}else{
		if(localStorage["wircos_session"])
		{
			//if(document.getElementById("e_user_email"))
			//	document.getElementById("e_user_email").value=localStorage["wircos_user"];
			test_token();
			show_logged(true);
		}else{
			show_logged(false);
		}
	}
	

	document.querySelectorAll(".tooltip").forEach(element=>{
		element.addEventListener('click', function(e)
		{
			e.preventDefault();
			let text=element.parentElement.querySelector(".tooltipvalue").innerText;
			navigator.clipboard.writeText(text);
			element.parentElement.querySelector(".tooltiptext_init").classList.add("hide");
			element.parentElement.querySelector(".tooltiptext_result").classList.remove("hide");	
		});
		element.addEventListener('mouseout', function(e)
		{
			e.preventDefault();
			element.parentElement.querySelector(".tooltiptext_result").classList.add("hide");
			element.parentElement.querySelector(".tooltiptext_init").classList.remove("hide");
		});
	});

	if(document.getElementById("btn_hide_nft_popup"))
	{
		document.getElementById("btn_hide_nft_popup").addEventListener("click",function(e)
		{
			e.preventDefault();
			hide_nft_options();
		});

		document.getElementById("btn_nft_destroy").addEventListener('click', function(e)
		{
			e.preventDefault();
			
			const element=document.querySelector(".nft_popup");
			const nft_id=element.getAttribute("aria-nft");
			hide_nft_options();

			modal_show(get_msg("delete_title"),get_msg("delete")+"<i>"+element.getAttribute("aria-title")+"</i>",
					"input",function(res)
			{
				if((res) && (res==get_msg("delete_word")))
				{
					let data={"email":localStorage["wircos_user"],"lng":page_lng,
								"token":localStorage["wircos_session"],"nft":nft_id};
					fetch(server_url+"/delete_nft",{
						method: "POST",
						headers: {"Access-Control-Allow-Origin": "*",
								   "Content-Type": "application/json"},
						body: JSON.stringify(data), 
					}).then((response)=>{
						if (response.status >= 200 && response.status <= 299) 
							return response.json();
						throw Error(response.statusText);
					}).then((response)=>{
						if(response.status)
						{   
							document.getElementById("nft_"+nft_id).remove();
							show_stats(response);
							
							modal_show(get_msg("delete_title"),get_msg("delete_ok"),"info",function(res)
							{
								if(response.count==0)
									show_nfts("nfts_grid",null,"nfts_grid_empty");
							});
						}else{
							modal_show(get_msg("delete_title"),get_msg("delete_ko")+
									get_msg("error_"+response.error)+" ("+response.error+").","error");
						}
					});
				}
			});
				
		},false);

		document.getElementById("btn_nft_update").addEventListener('click', function(e)
		{
			e.preventDefault();
			
			const element=document.querySelector(".nft_popup");
			const nft_id=element.getAttribute("aria-nft");
			hide_nft_options();

			let data={"email":localStorage["wircos_user"],"lng":page_lng,
						"token":localStorage["wircos_session"],"nft":nft_id,
						"price":document.getElementById("nft_edit_price").value,
						"status":document.getElementById("nft_edit_status").value.charAt(0)};
			fetch(server_url+"/update_nft",{
				method: "POST",
				headers: {"Access-Control-Allow-Origin": "*",
							"Content-Type": "application/json"},
				body: JSON.stringify(data), 
			}).then((response)=>{
				if (response.status >= 200 && response.status <= 299) 
					return response.json();
				throw Error(response.statusText);
			}).then((response)=>{
				if(response.status)
				{   
					document.getElementById("nft_"+nft_id).setAttribute("aria-status",data.status);
					document.getElementById("nft_"+nft_id).querySelector(".nft_cost").innerText=data.price;
							
					modal_show(get_msg("update_title"),get_msg("update_ok"),"info",function(res)
					{
						// change nft id and status
					});
				}else{
					modal_show(get_msg("update_title"),get_msg("update_ko")+
							get_msg("error_"+response.error)+" ("+response.error+").","error");
				}
			});
		},false);

		document.getElementById("btn_save_profile").addEventListener('click', function(e)
		{
			e.preventDefault();
			const form = new FormData();
			form.append("email",localStorage["wircos_user"]);
			form.append("lng",page_lng);
			form.append("token",localStorage["wircos_session"]);
			form.append("account_bio",document.getElementById("account_bio").value);
			form.append("account_username",document.getElementById("account_username").value);

			if(document.getElementById("account_picture").files[0])
				form.append('account_picture',document.getElementById("account_picture").files[0]);

			fetch(server_url+"/update_settings",{
				method: "POST",
				headers: {"Access-Control-Allow-Origin": "*",
						  //"Content-Type": "multipart/form-data" //"application/json"
						},
				body: form,  
			}).then((response)=>{
				if (response.status >= 200 && response.status <= 299) 
					return response.json();
				throw Error(response.statusText);
			}).then((response)=>{
				if(response.status)
				{   
					if(document.getElementById("account_picture").files[0])
					{
						document.getElementById("nav_user_picture").setAttribute("src",
							document.getElementById("profile_user_picture").getAttribute("src"));
					}
					if(document.getElementById("account_username").value!="")
					{
						document.getElementById("nav_user_username").innerHTML=document.getElementById("account_username").value;
					}else{
						document.getElementById("nav_user_username").innerHTML=get_msg("user_anonymous");
					}
					modal_show(get_msg("update_settings_title"),
						get_msg("update_settings_ok"),"info",function(res)
					{
						
					});
				}else{
					modal_show(get_msg("update_settings_title"),get_msg("update_settings_ko")+
							get_msg("error_"+response.error)+" ("+response.error+").","error");
				}
			});
		},false);

		document.getElementById("nft_edit_view").addEventListener("click",function(e)
		{
			e.preventDefault();
			const form=document.getElementById("nft_access_form");
			form.setAttribute("action",this.getAttribute("href"));
			form.querySelector("[name=nft_access_lng]").value=page_lng;
			form.querySelector("[name=nft_access_user]").value=localStorage["wircos_user"];
			form.querySelector("[name=nft_access_session]").value=localStorage["wircos_session"];

			form.submit();

		},false);
	}

	

	// author info
	let username='all';
	
	if(document.getElementById("author_info"))
	{
		username=urlParams.keys().next().value;
		load_author_info("author_info",username);	
	}
	if(document.getElementById("explore_authors"))
	{
		show_featured_authors("explore_authors",null,5);
		load_featured_authors("explore_authors",0);	
	}

	document.querySelectorAll(".items_section").forEach(element=>{
		load_items(element,username);	
	});
	document.querySelectorAll(".grid_section").forEach(element=>{
		load_authors(element,1);
	});

}


function show_contact_result(el)
{
	if(el)
	{
		document.querySelector("."+el).classList.remove("hide");
		document.querySelector(".contact_result").classList.remove("hide");
	}else{
		document.querySelector(".contact_result").classList.add("hide");
		document.querySelectorAll(".contact_result_ok, .contact_result_ko").forEach(element=>{
			element.classList.add("hide");
		});
	}
}

function show_logged(show)
{
	if(show)
	{
		document.querySelectorAll(".logged").forEach(element=>{
			element.classList.remove("hide");
		});
		document.querySelectorAll(".no_logged").forEach(element=>{
			element.classList.add("hide");
		});
		if(document.getElementById("e_user_email"))
			document.getElementById("e_user_email").value=localStorage["wircos_user"];
	}else{
		document.querySelectorAll(".logged").forEach(element=>{
			element.classList.add("hide");
		});
		document.querySelectorAll(".no_logged").forEach(element=>{
			element.classList.remove("hide");
		});

		//document.getElementById("nav_user_picture").classList.add("hide");
		//document.getElementById("nav_user_unknown").classList.remove("hide");

	}
	document.getElementById("nav_menu").classList.remove("hide");
}

function test_token()
{
	verify_token(localStorage["wircos_session"],localStorage["wircos_user"],function(status,data)
		{
			if(status)
			{ 
				show_logged(true);
				
				if(data.user["user_creator"])
				{
					document.querySelectorAll(".just_creator").forEach(element=>{
						element.classList.remove("hide");
					});
				}

				if(data.user["user_picture"])
				{
					if(document.getElementById("profile_user_picture"))
					{
						document.getElementById("profile_user_picture").setAttribute("src",data.user["user_picture"]);
						document.getElementById("profile_user_picture").classList.remove("hide");
						document.getElementById("profile_user_nopicture").classList.add("hide");
					}
					document.getElementById("nav_user_picture").setAttribute("src",data.user["user_picture"]);
					document.getElementById("nav_user_unknown").classList.add("hide");
					document.getElementById("nav_user_picture").classList.remove("hide");
				}else{
					document.getElementById("nav_user_picture").classList.add("hide");
					document.getElementById("nav_user_unknown").classList.remove("hide");
				}

				/*
				document.querySelectorAll(".pay_day").forEach(element=>{
					element.innerHTML=new Date(data.user["pay_day"]).toLocaleDateString(page_lng,
										{year: "numeric",month: "2-digit",day: "2-digit"});
				});
				*/
				if(document.getElementById("account_email"))
					document.getElementById("account_email").value=data.user["user_email"];
		
				if(data.user["user_username"])
				{
					if(document.getElementById("account_username"))
						document.getElementById("account_username").value=data.user["user_username"];
					document.getElementById("nav_user_username").innerHTML=data.user["user_username"];
				}else{
					document.getElementById("nav_user_username").innerHTML=get_msg("user_anonymous");
				}
				
				if(data.user["user_tokens"])
					document.getElementById("nav_user_tokens").innerHTML=data.user["user_tokens"];
				
				if(data.user["user_bio"])
					if(document.getElementById("account_bio"))
						document.getElementById("account_bio").value=data.user["user_bio"];
				
					
				//if(data.stats)
				//	show_stats(data.stats);

				if(data.items)
				{
					if(document.getElementById("account_page"))
					{
						show_items(document.querySelector(".items_grid"),data.items);
					}else{
					//,"nfts_grid_empty" OJO
					}
				}
				
			}else{
				show_logged(false);
				localStorage.removeItem("wircos_session");

			}
	});
}

async function validate_token(token,callback)
{
	try
	{
		const res=await fetch(server_url+"/session/validate/"+page_lng+"/"+token);
		let data = await res.json();
		if(!data) 
		{
			callback(false,"server_error");
			return;
		}
		if(!data.status)
		{
			callback(false,data.error);
			return;
		}
		callback(true,data.new_token);
	}catch(e)
	{
		callback(false,"server_error");
	}
}

async function verify_token(token,email,callback)
{
	try
	{
		const res=await fetch(server_url+"/session/verify/"+email+"/"+token+"/"+page_lng);
		let data = await res.json();
		console.log(data);
		if(!data) 
		{
			callback(false,"server_error");
			return;
		}
		if(!data.status)
		{
			callback(false,data.error);
			return;
		}
		callback(true,data);
	}catch(e)
	{
		console.log(e);
		callback(false,"server_error");
	}
}

function wait_for_session()
{
	if(localStorage["wircos_session"])
	{
		document.querySelector(".login_wait").classList.add("hide");
		document.querySelector(".login_close_window").classList.remove("hide");
	}else{
		setTimeout(wait_for_session,2000);
	}
}

function handleLogin(e)
{
    e.preventDefault();

	document.querySelector(".login_error").classList.add("hide");
	let data={"email":document.getElementById("login_email").value,"lng":page_lng,"server":server_url};
	if(data["email"]=="") return;
	
	if(this.classList.contains("btn_disabled")) return;

	this.classList.add("btn_disabled");
	fetch(server_url+"/session/magiclink",{
                method: "POST",
                headers: {
					"Access-Control-Allow-Origin": "*",
					'Content-Type': 'application/json'},
                body: JSON.stringify(data), 
            }).then((response)=>{
				if (response.status >= 200 && response.status <= 299) 
				{
		            return response.json();
				}else{
					this.classList.remove("btn_disabled");
					document.querySelector(".login_error").innerHTML=get_msg("error_100");
					document.querySelector(".login_error").classList.remove("hide");
				}
	        }).then((response)=>{
				if(response.status)
				{
					document.querySelector(".login_form").classList.add("hide");
					document.querySelector(".login_wait").classList.remove("hide");
					localStorage["wircos_user"]=document.getElementById("login_email").value;

					// comprobar que se está la sesión
					wait_for_session();

				}else{
					this.classList.remove("btn_disabled");
					document.querySelector(".login_error").innerHTML=get_msg("error_"+response.error);
					document.querySelector(".login_error").classList.remove("hide");
				}
			}).catch((error)=>{
				this.classList.remove("btn_disabled");
				document.querySelector(".login_error").innerHTML=get_msg("error_100");
				document.querySelector(".login_error").classList.remove("hide");
			}
		);
}

const handleLogout = async (e) => {
	e.preventDefault();
	show_logged(false);
	localStorage.removeItem("wircos_session");
	localStorage.removeItem("wircos_useremail");
};

function show_nft_options(nft,thumb,title,price,status,checkout,url)
{
	const popup=document.querySelector(".nft_popup");
	
	if(nft==popup.getAttribute("aria-nft"))
	{
		hide_nft_options();
		return;
	}
	popup.setAttribute("aria-nft",nft);
	popup.setAttribute("aria-title",title);
	popup.querySelector(".nft_popup_image").setAttribute("src",thumb);
	document.getElementById("nft_edit_title").innerHTML=title;
	document.getElementById("nft_edit_price").value=price;
	document.getElementById("nft_edit_status").value=status;
	//document.getElementById("nft_edit_checkout").innerText=checkout;
	document.getElementById("nft_edit_checkout").setAttribute("href",checkout);
	//document.getElementById("nft_edit_view").innerText=url;
	document.getElementById("nft_edit_view").setAttribute("href",url);
	popup.classList.remove("nft_popup_hide");
}

function hide_nft_options()
{
	const popup=document.querySelector(".nft_popup");
	popup.classList.add("nft_popup_hide");	
	popup.setAttribute("aria-nft","0");
}

async function load_transactions()
{
	let form=document.getElementById("transaction_form");
    let data = new FormData(form);
	data.append("email",localStorage["wircos_user"]);
	data.append("token",localStorage["wircos_session"]);

	const plainFormData = Object.fromEntries(data.entries());
	console.log(JSON.stringify(plainFormData));
	
	try{
		const res = await fetch(server_url+"/load_transactions", 
		{
			method: 'POST',
			headers: {'Accept': 'application/json', 'Content-Type': 'application/json'},
			body: JSON.stringify(plainFormData)
	  	});			
	  	if(!res)  throw new Error("100");
		let movs=null;
	  	try{
			movs = await res.json();
			
			const table=document.getElementById("transactions_list")
			
			while (table.firstChild)
				  table.removeChild(table.firstChild);

			if((!movs.data) || (movs.data.length==0))
			{
				document.getElementById("no_transactions").classList.remove("hide");
				document.querySelector(".transactions_total").classList.add("hide")
				return;
			}
			document.getElementById("no_transactions").classList.add("hide");
			document.querySelector(".transactions_total").classList.remove("hide")
			//console.log(movs);
			
			document.getElementById("transactions_total_sales").innerHTML=movs.stats.total+" <small>€</small>";

			let original_item=document.querySelector(".elements .mov_item");
			
			movs.data.forEach(function(mov) {
				let item=original_item.cloneNode(true);
				item.querySelector(".pay_date").innerText= new Date(mov.pay_date).toLocaleDateString(page_lng,
				{
					year: "numeric",
					month: "2-digit",
					day: "2-digit"
				});
				item.querySelector(".pay_title").innerText=mov.nft_title;
				item.querySelector(".pay_type").innerText=get_msg("pay_type_"+mov.pay_type);
				item.querySelector(".pay_revenue").innerHTML=mov.pay_revenue+" <small>€</small>";
				item.querySelector(".pay_payed").innerHTML=get_msg("pay_transfer_"+mov.pay_transfer);
				table.appendChild(item);
			});

	  	}catch(e){
			throw new Error("100");
		}
	  	if(!movs.status)  throw new Error({code:movs.error});
	}catch(error){
		modal_show(get_msg("transactions_title"),
						get_msg("error_"+error.message)+" ("+error.message+").","error");
	}

}

document.addEventListener("DOMContentLoaded", load, false);


// explore
function show_items(grid,data,empty)
{
	//grid.innerHTML="";
	//grid.classList.remove("hide");

	data.forEach(function(nft) {
		let item=document.querySelector('.elements .explore_item').cloneNode(true);
		item.setAttribute("id","item_"+nft.nft_id);
		item.setAttribute("aria-status",nft.nft_status);
		
		item.setAttribute("href","/item.html?"+nft.nft_slug);

		item.querySelector(".item_thumbnail").setAttribute('src', nft.nft_thumbnail_url);
		let info=item.querySelector('.item_info');
		info.querySelector(".item_cost").append(nft.nft_price.toLocaleString("es-US"));
		
		info.querySelector(".item_views").append(nft.nft_views);
		if(nft.nft_serie_total>1)
			info.querySelector(".item_copies").append(nft.nft_copies+"/"+nft.nft_serie_total);
		else
			info.querySelector(".item_stats_copies").classList.add("hide");
		item.querySelector(".item_title").append(nft.nft_title);
		info.querySelector(".item_description").append(nft.nft_description);

		info.querySelector(".item_type_"+nft.nft_type).classList.remove("hide");
		info.querySelector(".item_date").append(new Date(nft.nft_date).toLocaleDateString(page_lng,
			{year: "2-digit",month: "2-digit",day: "2-digit"}));

		if(nft.user_picture)
			info.querySelector(".item_picture").setAttribute("src",nft.user_picture);

		if(nft.user_username)
		{
			info.querySelector(".item_author_link").innerHTML=nft.user_username;
			info.querySelector(".item_author_link").setAttribute("href","/author.html?"+nft.user_username);
			info.querySelector(".item_author_link").addEventListener("click",function(e)
			{
				e.preventDefault();
				window.location.href = this.getAttribute("href");
				
			});
		}
		//
		//item.addEventListener("click",function(e)
		//{
		//	e.preventDefault();
		//	window.location.href = "https://www.w3schools.com";
					/*
			show_nft_options(nft.nft_id,
							item.querySelector(".nft_thumbnail").getAttribute('src'),
							nft.nft_title,
							info.querySelector(".nft_cost").innerHTML,
							item.getAttribute("aria-status")+page_lng,
							nft.nft_checkout, nft.nft_url
							);
			*/				
		//});

		grid.append(item);
	});
}
async function load_items(where,username='all')
{
	const items_empty=where.querySelector(".items_empty");
	const items_loading=where.querySelector(".items_loading");
	const items_error=where.querySelector(".items_error");
	const items_grid=where.querySelector(".items_grid");

	items_empty.classList.add("hide");
	items_error.classList.add("hide");
	items_loading.classList.remove("hide");
	
	const res=await fetch(server_url+"/get_items/"+username+"/"+items_grid.getAttribute("aria-author"));
	let data = await res.json();
	setTimeout(function()
	{
			items_loading.classList.add("hide");
			if(((!data) || (!data.items)) || (data.status==false))
			{
				items_error.classList.remove("hide");
				return;
			}
			
			if(data.items.length==0)
			{
				items_empty.classList.remove("hide");
				return;
			}
			
			show_items(items_grid,data.items);
	},500);
}
async function load_authors(where,username='all')
{
	const grid_empty=where.querySelector(".grid_empty");
	const grid_loading=where.querySelector(".grid_loading");
	const grid_error=where.querySelector(".grid_error");
	const grid_list=where.querySelector(".grid_list");

	grid_empty.classList.add("hide");
	grid_error.classList.add("hide");
	grid_loading.classList.remove("hide");
	
	let json=null;
	try{
		const res=await fetch(server_url+"/get_authors");
		json = await res.json();
	}catch(e){
		grid_loading.classList.add("hide");
		grid_error.classList.remove("hide");
		return;
	}
	setTimeout(function()
	{
			grid_loading.classList.add("hide");
			if(((!json) || (!json.data)) || (json.status==false))
			{
				grid_error.classList.remove("hide");
				return;
			}
			
			if(json.data.length==0)
			{
				grid_empty.classList.remove("hide");
				return;
			}
			fill_grid(grid_list,json.data);
	},1);
}

function fill_grid(grid,data)
{
	const node=document.querySelector(".elements ."+grid.getAttribute("aria-node"));
	if(!node) return;

	grid.innerHTML="";
	for(let a=0;a<data.length;a++)
	{
		let el=node.cloneNode(true);
		el.querySelector(".author_username").innerHTML=data[a].user_username;
		el.querySelector(".author_bio").innerHTML=data[a].user_bio;
		el.querySelector(".author_picture").setAttribute("src",data[a].user_picture);
		el.querySelector(".author_items").innerHTML=data[a].user_items;
		el.querySelector(".author_followers").innerHTML=data[a].user_followers;
		el.setAttribute("href","author.html?"+data[a].user_username)
		grid.appendChild(el);
	}
}

async function load_featured_authors(where,type)
{
	const res=await fetch(server_url+"/get_featured_authors");
	let data = await res.json();
	
	setTimeout(function()
	{
		if(((!data) || (!data.authors)) || (data.status==false))
		{
			console.log(data.error);
			return;
		}
			
		if(data.authors.length==0)
		{
			return;
		}		
		show_featured_authors(where,data.authors,data.authors.lengtj);
	},100);
}

async function load_author_info(where,username)
{
	
	const res=await fetch(server_url+"/get_author/"+username);
	let data = await res.json();
	console.log(data);
	
	if(((!data) || (!data.author)) || (data.status==false))
	{
		console.log(data.error);
		return;
	}
	
	const parent=document.getElementById(where);
	parent.querySelector(".author_username").innerHTML=data.author.user_username;
	parent.querySelector(".author_bio").innerHTML=data.author.user_bio;
	parent.querySelector(".author_picture").setAttribute("src",data.author.user_picture);
	parent.querySelector(".author_followers").innerHTML=data.author.user_followers;
	parent.querySelector(".author_items").innerHTML=data.author.user_items;
	
	data.author.user_media.forEach(function(media)
	{
		let el=document.querySelector('.elements .author_media_item').cloneNode(true);
		el.innerHTML=media.type;
		el.setAttribute("href",media.url);
		parent.querySelector(".author_media").append(el);
	});
}